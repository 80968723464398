var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",class:{ 'section-invert': _vm.invertColors }},[_c('v-container',{attrs:{"fluid":_vm.data.configuration.wb_container_fluid__checkbox__}},[(_vm.hasTextToDisplay(_vm.data.configuration.wb_text__html_text__))?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(_vm.data.configuration.wb_text__html_text__)}})],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},_vm._l((_vm.data.configuration.wb_features__array_section_features__),function(item,index){
var _obj;
return _c('div',{key:index,staticClass:"d-flex flex-column text-center align-center",style:({
             width: _vm.isMobile ? '100%' : _vm.data.configuration.wb_column_width__number__ + '%',
             paddingLeft: _vm.data.configuration.wb_gallery_distance__number__ + 'px !important',
             paddingRight: _vm.data.configuration.wb_gallery_distance__number__ + 'px !important'
           })},[(!_vm.imageHidden && (!_vm.isMobile || _vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__))?_c('v-img',{class:( _obj = {}, _obj[_vm.classForImageRound] = true, _obj ),style:({
            marginBottom: _vm.data.configuration.wb_image_margin_bottom__number__ + 'px',
          }),attrs:{"contain":_vm.data.configuration.wb_image_contain__checkbox__,"width":"100%","min-height":_vm.data.configuration.wb_image_height__number__,"max-height":_vm.data.configuration.wb_image_height__number__,"src":_vm.getImageUrl(item.uuid, true)}}):_vm._e(),_c('v-sheet',{staticClass:"pa-2",style:({ marginBottom: _vm.data.configuration.wb_text_margin_bottom__number__ + 'px'}),attrs:{"color":_vm.backgroundColor1}},[_c('div',{staticStyle:{"text-align":"left"},style:({ color: _vm.textColor1 }),domProps:{"innerHTML":_vm._s(item.description)}}),(item.buttons && item.buttons.length > 0)?_c('div',{staticClass:"mt-2"},_vm._l((item.buttons),function(button,index){return _c('default-button',{key:index,attrs:{"button":_vm.transformCtaButton(button),"additional-class":"ma-2"}},[_vm._v(" "+_vm._s(button.title)+" ")])}),1):_vm._e()])],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }